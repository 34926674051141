import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link, Heading, Image } from '@chakra-ui/react';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_RESEPSI_TIME,
  PREWEDDING_EMBED,
} from '@/constants';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { ASSETS_FLOWER_2A } from '@/constants/assets';

import { ENABLE_LIVE_STREAMING } from '@/constants/feature-flags';
import { TITLE_SECTION_STYLES } from '@/constants/typography'

import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  return (
    <Box position="relative" textAlign="center" {...rest}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%">
        <WithAnimation>
          <Heading
            {...TITLE_SECTION_STYLES}
            marginTop={isInvitation ?"48px" :"12px"}
            fontSize="40px"
          >
           Live Streaming
          </Heading>
        </WithAnimation>
        {ENABLE_LIVE_STREAMING && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              margin="2px 0 32px"
              dangerouslySetInnerHTML={{
                __html: `Akad | 26 April 2024 | ${WEDDING_AKAD_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        {/* Live stream section */}
        <Box pos="absolute" right="-20px" bottom="610px" zIndex="5" transform="rotateY(180deg)">
          <WithAnimation right>
            <Image src={ASSETS_FLOWER_2A} width="110px" style={{ transform: 'rotate(36deg)' }} />
          </WithAnimation>
        </Box>
        <Box pos="absolute" left="-20px" bottom="-20px" zIndex="5" transform="rotateY(180deg)">
          <WithAnimation left>
            <Image src={ASSETS_FLOWER_2A} width="110px" style={{ transform: 'rotate(36deg)' }} />
          </WithAnimation>
        </Box>
        <WithAnimation right>
          <Center>
          <Box pos="relative" width="85%">
            {false && (
              <AspectRatio maxW="560px" ratio={16 / 9}>
                <iframe
                  id="ytplayer"
                  title={`Live wedding of ${THE_BRIDE}`}
                  src={YOUTUBE_EMBED}
                  allowFullScreen
                  style={{ borderRadius: '16px' }}
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </AspectRatio>
            )}
            <Image
              src={`https://ik.imagekit.io/zaanfa2/munika-alif/thumbnail.jpg?updatedAt=1710811856856`}
              width="100%"
              borderRadius="16px"
            />
          </Box>
          </Center>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="12px 32px 0 32px"
            fontStyle="italic"
          >
            Siaran langsung Akad Nikah dapat disaksikan melalui Instagram Live pada akun @munikadayanti, klik tombol di bawah:
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href="https://www.instagram.com/munikadayanti" target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="32px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                Buka IG @munikadayanti
              </Button>
            </Link>
          </Center>
        </WithAnimation>
        {ENABLE_LIVE_STREAMING && false && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              margin="12px 0 32px"
              dangerouslySetInnerHTML={{
                __html: `Reception | ${WEDDING_AKAD_FULLDATE} | ${WEDDING_RESEPSI_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        {true && (
          <WithAnimation>
            <Heading
              {...TITLE_SECTION_STYLES}
              margin="24px 0 -24px"
              fontSize="40px"
              textTransform="none"
            >
              Portraits of Us
            </Heading>
          </WithAnimation>
        )}
        {false && (
          <WithAnimation>
            <Center margin="12px 0 -12px">
              <Image
                src={`https://ik.imagekit.io/zaanfa2/munika-alif/logo.png?updatedAt=1710801813556`}
                width="100%"
                maxWidth="150px"
              />
            </Center>
          </WithAnimation>
        )}
        <WithAnimation>
          <Text
            fontSize="lg"
            textAlign="center"
            color="mainColorText"
            margin="24px 0 32px"
            dangerouslySetInnerHTML={{
              __html: `Ever thine, ever mine, ever ours.`,
            }}
          />
        </WithAnimation>
        <WithAnimation left>
          <Center>
          <Box pos="relative" width="85%">
            <AspectRatio maxW="560px" ratio={16 / 9}>
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={PREWEDDING_EMBED}
                allowFullScreen
                style={{ borderRadius: '16px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
          </Box>
          </Center>
        </WithAnimation>
        {false && (
          <WithAnimation left>
            <Text
              textAlign="center"
              fontSize="md"
              color="mainColorText"
              margin="16px 0"
              padding="12px 32px 0 32px"
            >
              {txt.problem[lang]}
            </Text>
          </WithAnimation>
        )}
        {false && (
          <WithAnimation left>
            <Center>
              <Link href={`https://cutt.ly/ResepsiZhidanAnnisa`} target="_blank" _hover={{ textDecoration: 'none' }}>
                <Button
                  size="xs"
                  fontWeight="normal"
                  bgColor="bgAlternative"
                  marginBottom="32px"
                  {...BUTTON_PROPS}
                  {...BUTTON_PADDING_PROPS}
                  fontSize="md"
                >
                  {`Live Reception`}
                </Button>
              </Link>
            </Center>
          </WithAnimation>
        )}
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);